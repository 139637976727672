import { useCallback, useRef } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'

export const useHandleVirtuosoScrollToTop = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const DEFAULT_BEHAVIOR = 'smooth'

  const scrollToTop = useCallback(
    (behavior: ScrollBehavior = DEFAULT_BEHAVIOR) => {
      // Note that the default parameters of ordinary functions are SyntheticBaseEvent
      // ref: https://legacy.reactjs.org/docs/handling-events.html

      virtuosoRef.current?.scrollTo({
        top: 0,
        behavior: typeof behavior === 'object' ? DEFAULT_BEHAVIOR : behavior,
      })
    },
    []
  )

  return { virtuosoRef, scrollToTop }
}
