import {
  ComponentType,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { VirtuosoHandle } from 'react-virtuoso'

import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { Loading } from '@/components/atoms/Loading'
import { Refresher } from '@/components/atoms/Refresher'
import { NoResults } from '@/components/molecules/NoResults'
import { TimelineError } from '@/components/organisms/TimelineError'
import { VirtualList } from '@/components/organisms/VirtualList'

import { useActivity } from '@/lib/hooks/useActivity'
import { useFeed } from '@/lib/hooks/useFeed'
import { useVirtualList } from '@/lib/hooks/useVirtualList'

import { QueryKeys } from '@/enums'

interface IFeedProps {
  feedGroup: 'timeline' | 'trending'
  header?: ComponentType
  virtuosoRef?: MutableRefObject<VirtuosoHandle>
  refetch?: () => void
}

export const TrendingFeed: FC<IFeedProps> = ({
  feedGroup,
  header: Banners,
  virtuosoRef,
  refetch,
}) => {
  const [isFeedEnabled, setIsFeedEnabled] = useState(true)
  const {
    feedData: trendingFeed,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetchFirstPage,
    isFetching,
    isLoading,
    hasFeedError,
  } = useFeed({
    feedGroup,
    feedId: undefined,
    cacheKey: [QueryKeys.TrendingFeed],
    ranking: 'popularity',
  })
  const refetchData = useCallback(async () => {
    refetchFirstPage()
    refetch()
  }, [refetchFirstPage, refetch])
  const { t } = useTranslation('common')

  const { isAtTop, atTopStateChange, onEndReached } = useVirtualList({
    hasNextPage,
    fetchNextPage,
  })

  const {
    activeActivityId,
    handleScroll,
    renderActivity,
    scrollSeekConfigurationExit,
    scrollSeekConfigurationEnter,
  } = useActivity({
    feed: trendingFeed,
    isAtTop,
    isFeedFetching: isFetching,
    isFeedEnabled,
  })

  const itemContent = useCallback(
    (index, item) => renderActivity(item, activeActivityId, index),
    [activeActivityId, renderActivity]
  )

  const Header = useCallback(() => {
    return (
      <>
        <Banners />
        <strong className="block px-3 pb-4 text-lg">
          {t('promoWidgets.trendingPosts.title')}
        </strong>
        {!trendingFeed?.length && !isLoading ? <NoResults /> : null}
      </>
    )
  }, [t, Banners, trendingFeed, isLoading])

  const Footer = useCallback(() => {
    return <InfiniteScrollLoader hasNext={!!isFetchingNextPage} />
  }, [isFetchingNextPage])

  useEffect(() => {
    setIsFeedEnabled(true)

    return () => setIsFeedEnabled(false)
  }, [])

  if (!trendingFeed?.length) {
    if (hasFeedError) {
      return <TimelineError translation={'timeline.errors.feed'} />
    }

    if (isFetching) {
      return <Loading />
    }
  }

  return (
    <>
      <Refresher disabled={!isAtTop} refetch={refetchData} />
      <VirtualList
        atTopStateChange={atTopStateChange}
        className="h-full mx-auto max-w-[40rem] ion-content-scroll-host"
        data={trendingFeed}
        endReached={onEndReached}
        onScroll={handleScroll}
        overscan={300}
        components={{
          Footer,
          Header,
        }}
        itemContent={itemContent}
        virtualListRef={virtuosoRef}
        scrollSeekConfiguration={{
          enter: scrollSeekConfigurationEnter,
          exit: scrollSeekConfigurationExit,
        }}
      />
    </>
  )
}
