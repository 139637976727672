import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
  isPlatform,
} from '@ionic/react'
import { App } from '@capacitor/app'
import { eyeOffOutline } from 'ionicons/icons'

import { BackButton } from '@components/atoms/BackButton'
import { Title } from '@components/atoms/Title'
import { Ellipsis } from '@/components/atoms/assets/Ellipsis'
import { Loading } from '@/components/atoms/Loading'
import { BlockUserAlert } from '@/components/molecules/Alert/BlockUserAlert'
import { LogoutAlert } from '@/components/molecules/Alert/LogoutAlert'
import { InviteFriendButton } from '@/components/molecules/InviteFriendButton'
import { Popover } from '@/components/molecules/Popover'
import {
  DepositCurrency,
  DepositCurrencyRef,
} from '@/components/organisms/DepositCurrency'
import { PaymentModal } from '@/components/organisms/Payment'
import { PaymentModalRef } from '@/components/organisms/Payment/PaymentModal/PaymentModal'
import { Entry } from '@/components/templates/entry'
import { UserProfileHeader } from './components'
import { useTermsAndConditionsModal } from './components/TermsAndConditionsModal'
import { UserProfileSections } from './components/UserProfileSections'

import { useAccountInfo } from '@services/account'
import { unfollow } from '@services/user'
import { appConfig } from '@/services/config'
import { reportUser } from '@/services/report'
import { SubscriptionFeedError } from '@/services/subscription'
import { queryClient } from '@lib/queryClient'
import { useChat } from '@/lib/hooks/chat/useChat'
import { useSubscription } from '@/lib/hooks/subscription'
import { useAcceptableUsePolicy } from '@/lib/hooks/subscription/useAcceptableUsePolicy'
import { useFeed } from '@/lib/hooks/useFeed'
import { useFollowButton } from '@/lib/hooks/useFollow'
import { useGetTopSupporters } from '@/lib/hooks/useGetTopSupporters'
import { useHandleVirtuosoScrollToTop } from '@/lib/hooks/useHandleVirtuosoScrollToTop'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useVirtualList } from '@/lib/hooks/useVirtualList'
import { useWallet } from '@/lib/hooks/wallet'
import { useLocation, useNavigate, useParams } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'

import { FollowStatus, Pages, ProfileHashTabs, QueryKeys } from '@enums'

import { useSocials } from '../Socials/hooks/useSocials'
import { useActionSheetButtons } from './hooks/useActionSheetButtons'
import { useHandleCheckTokenStatus } from './hooks/useHandleCheckTokenStatus'
import { useProfileSections } from './hooks/useProfileSections'

// Component is used for the own Profile (without userId) and other profiles (userId given)

App.addListener('resume', () => {
  // On own Profile page
  const pathname = window.location.pathname
  pathname === `/${Pages.Profile}` &&
    Tracking.triggerEvent(TrackingEvent.Profile)

  // When the user id exists and is on the Profile page
  const pathnameArray = pathname?.split('/')
  const profileUserId = pathnameArray?.[2]
  if (!(profileUserId && pathname.includes(Pages.Profile))) return

  Tracking.triggerEvent(TrackingEvent.OtherProfileViewed, {
    profileUserId,
  })
})

export const UserProfile: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname = '' } = location
  const { t } = useTranslation('common')
  const [showActionSheet, setShowActionSheet] = useState(false)
  const [popover, setPopover] = useState({
    isVisible: false,
    message: null,
  })
  const [isBlockPopoverVisible, setIsBlockPopoverVisible] = useState(false)
  const [isLogoutPopoverVisible, setIsLogoutPopoverVisible] = useState(false)
  const { virtuosoRef, scrollToTop } = useHandleVirtuosoScrollToTop()

  const { userId } = useParams<{ userId: UUID }>()

  const { data: authUser } = useUserInfo()
  const computedUserId = useMemo(
    () => userId || authUser?.id,
    [userId, authUser?.id]
  )

  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    refetch: refetchUserInfo,
  } = useUserInfo({ userId: computedUserId })
  useHandleCheckTokenStatus(computedUserId)

  useEffect(() => {
    refetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: accountInfoData,
    refetch: refetchAccountInfo,
    isLoading: isAccountLoading,
  } = useAccountInfo(userInfo?.id === authUser?.id ? undefined : userInfo?.id)

  const isAuthUser = useMemo(() => {
    return userInfo?.id === authUser?.id
  }, [userInfo, authUser])

  const { status, refetch, toggleBlock } = useFollowButton(userInfo?.id)

  const {
    feedData: feed,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    hasFeedError,
    refetchFirstPage,
    isFetching: isFeedFetching,
  } = useFeed({
    feedGroup: 'user',
    feedId: userInfo?.id,
    cacheKey: [QueryKeys.UserFeed, userInfo?.id],
    enabled: !!userInfo?.id,
    ranking: undefined,
  })

  const {
    feedData: subscriptionFeed,
    hasNextPage: hasNextSubscriptionPage,
    fetchNextPage: fetchNextSubscriptionPage,
    isFetchingNextPage: isFetchingNextSubscriptionPage,
    error: subscriptionFeedError,
    refetchFirstPage: refetchFirstSubscriptionPage,
    isFetching: isSubscriptionFeedFetching,
    isLoading: isSubscriptionFeedLoading,
    isFetchedAfterMount: isSubscriptionFetchedAfterMount,
  } = useFeed({
    feedGroup: 'subscription',
    feedId: userInfo?.id,
    cacheKey: [QueryKeys.SubscriptionFeed, userInfo?.id],
    enabled: !!userInfo?.id,
    ranking: undefined,
    userId: userInfo?.id,
  })

  const { sectionIndex, navigateSectionIndex, findSectionIndex } =
    useProfileSections({
      subscriptionFeedError,
      isSubscriptionFeedLoading,
      isSubscriptionFeedFetching,
      username: userInfo?.username,
    })

  const { refetchSocialsList } = useSocials({
    userId: userInfo?.id,
    enabled: false,
  })

  const { data: topSupporters, refetch: refetchTopSupporters } =
    useGetTopSupporters(userInfo?.id)
  const { wallet, refetchWallet } = useWallet({
    userInfo,
    enabled: !!userInfo?.id,
  })

  const { refetch: refetchChatData } = useChat({
    userId: userInfo?.id,
    enabled: false,
  })

  const { refetchSubscription } = useSubscription()

  const refetchAll = async () => {
    await Promise.all(
      [
        refetchFirstPage(),
        refetchFirstSubscriptionPage(),
        refetchWallet(),
        refetchAccountInfo(),
        refetchUserInfo(),
        refetchSocialsList(),
        refetchTopSupporters(),
        !isAuthUser && refetchChatData(),
        isAuthUser && refetchSubscription(),
      ].filter(Boolean)
    )
  }

  useEffect(() => {
    if (isAuthUser) {
      queryClient.setQueryData(['user'], userInfo)
    }
  }, [userInfo, isAuthUser])

  // Scrolling to the top each time the userId changes. Without the timeout it
  // wants to scroll before list is ready
  useEffect(() => {
    setTimeout(() => {
      virtuosoRef.current?.scrollTo({
        top: 0,
      })
    }, 100)
  }, [userId, virtuosoRef])

  useEffect(() => {
    // On own Profile page
    if (pathname === `/${Pages.Profile}`) {
      return Tracking.triggerEvent(TrackingEvent.Profile)
    }

    // On other profiles tracking (When the user id exists and is on the Profile page)
    if (!(userId && pathname.includes(Pages.Profile))) return

    Tracking.triggerEvent(TrackingEvent.OtherProfileViewed, {
      visitorProfileId: authUser?.id,
      profileUserId: userId,
    })
  }, [pathname, userId, authUser?.id])

  const header = useMemo(() => {
    const username =
      userInfo?.username && !isUserInfoLoading && !isAccountLoading
        ? `@${userInfo?.username}`
        : ''
    if (!username) return

    return (
      <>
        <IonButtons slot="secondary">{!!userId && <BackButton />}</IonButtons>
        <IonButtons className="pr-[0.0625rem]" slot="primary">
          <>
            <InviteFriendButton sharedUserId={userId} iconOnly />
            {status !== FollowStatus.Blocking &&
              status !== FollowStatus.Blocked && (
                <IonButton
                  data-test="profile-menu"
                  className="plain"
                  disabled={false}
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowActionSheet(true)
                  }}
                >
                  <Ellipsis className="text-primary" />
                </IonButton>
              )}
          </>
        </IonButtons>
        <IonTitle>
          <Title
            level={3}
            className="text-ellipsis overflow-hidden font-bold"
            onClick={scrollToTop}
          >
            {username}
          </Title>
        </IonTitle>
      </>
    )
  }, [
    isUserInfoLoading,
    userInfo,
    userId,
    status,
    isAccountLoading,
    scrollToTop,
  ])

  const onBlockUser = async () => {
    try {
      toggleBlock()
      setIsBlockPopoverVisible(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onUnfollowUser = async (message: string) => {
    try {
      await unfollow({ userId })
      await refetch()
      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const onReportUser = async (message: string) => {
    try {
      await reportUser({
        userId,
      })
      setPopover({ isVisible: true, message })
    } catch (e) {
      console.log(e)
    }
  }

  const onLogout = async () => {
    setIsLogoutPopoverVisible(false)
    navigate(Routes.LOGOUT, {
      state: {
        direction: 'none',
      },
    })
  }

  const reportUserMenuItem = () => ({
    text: t('report.reportUser.buttonLabel'),
    role: 'destructive',
    data: {
      type: 'delete',
    },
    handler: () => onReportUser(t('report.reportUser.confirmationMessage')),
  })

  const { mutateAcceptableUsePolicy, acceptableUsePolicy } =
    useAcceptableUsePolicy()
  const { presentModal } = useTermsAndConditionsModal({
    onConfirm: () => {
      mutateAcceptableUsePolicy()
      navigate(Routes.CONFIGURE_SUBSCRIPTION)
    },
  })

  const actionSheetButtons = useActionSheetButtons({
    isAuthUser,
    acceptableUsePolicy,
    presentModal,
    onUnfollowUser,
    reportUserMenuItem,
    setIsBlockPopoverVisible,
    setIsLogoutPopoverVisible,
  })

  const { isAtTop, onEndReached, atTopStateChange } = useVirtualList({
    hasNextPage,
    hasNextSubscriptionPage,
    fetchNextPage,
    fetchNextSubscriptionPage,
    sectionIndex,
    subscriptionFeedError,
  })

  const { isZeroBalance } = useWallet({
    userInfo: authUser,
  })
  const currency = appConfig.currency
  const depositCurrencyRef = useRef<DepositCurrencyRef>()
  const paymentModalRef = useRef<PaymentModalRef>()

  const translation = {
    paymentType: t('paymentSubscription.unlockSubscription'),
    withUsername: t('paymentSubscription.withUsername', {
      username: userInfo?.username,
    }),
    price: t('paymentSubscription.labels.price'),
    republikFee: t('paymentSubscription.labels.republikFee'),
    totalPayment: t('paymentSubscription.labels.total'),
    yourWalletBalance: t('paymentSubscription.labels.yourWalletBalance'),
    pay: t('paymentSubscription.actions.pay'),
    info: t('paymentSubscription.info'),
    paymentSuccessful: t('paymentSubscription.paymentSuccessful'),
    paymentUnlockedWith: t('paymentSubscription.paymentUnlockedWith'),
    startToAction: t('paymentSubscription.actions.startToSubscribe'),
    errorSummaryTitle: t('unlockChat.errorSummaryTitle'),
    errorSummaryDetails: t('unlockChat.errorSummaryDetails'),
    close: t('unlockChat.actions.close'),
  }

  const onSubscribe = useCallback(
    (e) => {
      if (isZeroBalance) {
        return depositCurrencyRef.current.openModal()
      }

      e.stopPropagation()
      paymentModalRef.current.openModal()
      // setModalId(`send-subscribe-modal-${uuid}`)
    },
    [isZeroBalance]
  )

  const handlePaymentSuccess = useCallback(() => {
    navigateSectionIndex(findSectionIndex(ProfileHashTabs.SUBSCRIPTION))
  }, [findSectionIndex, navigateSectionIndex])

  const subscriptionPrice = useMemo(
    () =>
      (subscriptionFeedError as unknown as SubscriptionFeedError)?.response
        ?.metadata?.price,
    [subscriptionFeedError]
  )

  const Header = useCallback(
    () => (
      <UserProfileHeader
        wallet={wallet}
        onSubscribe={onSubscribe}
        userId={userInfo?.id}
        isAuthUser={isAuthUser}
        userInfo={userInfo}
        accountInfoData={accountInfoData}
        topSupporters={topSupporters.supporters}
        subscriptionFeedError={subscriptionFeedError}
        isSubscriptionFeedFetching={isSubscriptionFeedFetching}
        isSubscriptionFetchedAfterMount={isSubscriptionFetchedAfterMount}
      />
    ),
    [
      isAuthUser,
      userInfo,
      accountInfoData,
      wallet,
      topSupporters,
      subscriptionFeedError,
      onSubscribe,
      isSubscriptionFeedFetching,
      isSubscriptionFetchedAfterMount,
    ]
  )

  const renderUserFeed = useCallback(
    () => (
      <div className="flex flex-col h-full">
        <UserProfileSections
          wallet={wallet}
          activities={feed}
          subscriptionActivities={subscriptionFeed}
          isFetchingNextPage={isFetchingNextPage}
          isSubscriptionFeedFetching={isSubscriptionFeedFetching}
          isSubscriptionFeedLoading={isSubscriptionFeedLoading}
          isFetchingNextSubscriptionPage={isFetchingNextSubscriptionPage}
          virtuosoRef={virtuosoRef}
          atTopStateChange={atTopStateChange}
          onEndReached={onEndReached}
          userId={userInfo?.id}
          isAuthUser={isAuthUser}
          hasFeedError={!!hasFeedError}
          isFeedFetching={isFeedFetching}
          creatorTokenId={userInfo?.creatorTokenId}
          userInfo={userInfo}
          accountInfoData={accountInfoData}
          subscriptionFeedError={subscriptionFeedError}
          topSupporters={topSupporters.supporters}
          onSubscribe={onSubscribe}
          isSubscriptionFetchedAfterMount={isSubscriptionFetchedAfterMount}
        />
      </div>
    ),
    [
      subscriptionFeedError,
      isSubscriptionFeedFetching,
      isFetchingNextSubscriptionPage,
      isSubscriptionFeedLoading,
      wallet,
      feed,
      isFetchingNextPage,
      virtuosoRef,
      atTopStateChange,
      onEndReached,
      isAuthUser,
      hasFeedError,
      isFeedFetching,
      userInfo,
      accountInfoData,
      subscriptionFeed,
      topSupporters,
      onSubscribe,
      isSubscriptionFetchedAfterMount,
    ]
  )

  const renderUserContent = useCallback(
    (followStatus: FollowStatus) => {
      switch (followStatus) {
        case FollowStatus.Blocking:
          return (
            <div className="max-w-[40rem] mx-auto">
              <Header />
              <div className="mx-auto mt-10 text-center">
                <p>
                  <strong>
                    {t('profile.youBlocked.title', {
                      username: userInfo?.username,
                    })}
                  </strong>
                </p>
                <p className="mt-2 opacity-70 text-sm">
                  {t('profile.youBlocked.description')}
                </p>
              </div>
            </div>
          )
        case FollowStatus.Blocked:
          return (
            <div className="max-w-[40rem] mx-auto">
              <Header />
              <div className="mx-auto mt-10 text-center">
                <IonIcon
                  className="text-red-500 w-12 h-12 mb-2"
                  icon={eyeOffOutline}
                />
                <div className="flex gap-2 items-center justify-center">
                  <p>{t('profile.youAreBlocked')}</p>
                </div>
              </div>
            </div>
          )
        default:
          return renderUserFeed()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo, t, Header, renderUserFeed, wallet]
  )

  return (
    <>
      <Popover
        hasIcon={false}
        isOpen={popover.isVisible}
        onDismiss={() =>
          setPopover({
            isVisible: false,
            message: null,
          })
        }
        actionText={t('ok')}
      >
        {popover.message}
      </Popover>
      <BlockUserAlert
        isOpen={isBlockPopoverVisible}
        onDismiss={() => setIsBlockPopoverVisible(false)}
        onConfirm={() => onBlockUser()}
        status={status}
        username={userInfo?.username}
      />
      <LogoutAlert
        isOpen={isLogoutPopoverVisible}
        onDismiss={() => setIsLogoutPopoverVisible(false)}
        onConfirm={() => onLogout()}
        username={userInfo?.username}
      />
      <Entry
        header={header}
        refetch={refetchAll}
        headTitle={t('common.head.title.profile')}
        isRefetchActive={isAtTop}
        onActiveNavIconPress={scrollToTop}
        hasData={!!userInfo}
        isPaddingEnabled={false}
      >
        <PaymentModal
          ref={paymentModalRef}
          userInfo={userInfo}
          amount={subscriptionPrice}
          translation={translation}
          paymentType="subscription"
          onPaymentSuccess={handlePaymentSuccess}
        />
        <DepositCurrency
          title={t('depositBottomSheet.topUpCurrencyToSubscribe', {
            currency,
          })}
          ref={depositCurrencyRef}
        />
        {!isAccountLoading &&
        !isUserInfoLoading &&
        !isSubscriptionFeedLoading ? (
          isAuthUser ? (
            renderUserFeed()
          ) : (
            renderUserContent(userInfo?.followStatus)
          )
        ) : (
          <Loading />
        )}
        <IonActionSheet
          isOpen={showActionSheet}
          header={!isAuthUser ? t('report.title') : undefined}
          mode={isPlatform('ios') ? 'ios' : 'md'}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={actionSheetButtons}
          htmlAttributes={{ 'data-test': 'profile-sheet' }}
        />
      </Entry>
    </>
  )
}
