import { CapacitorHttp, HttpOptions } from '@capacitor/core'
import { AxiosRequestConfig, Method } from 'axios'
import {
  ClientOptions,
  DefaultGenerics,
  SiteError,
  StreamApiError,
  StreamClient,
  UR,
} from 'getstream'

type AxiosConfig = {
  token: string
  url: string
  axiosOptions?: AxiosRequestConfig
  body?: unknown
  headers?: UR
  qs?: UR
  serviceName?: string
}

export class CustomStreamClient<
  StreamFeedGenerics extends DefaultGenerics = DefaultGenerics
> extends StreamClient<StreamFeedGenerics> {
  doAxiosRequest = async <T>(
    method: Method,
    options: AxiosConfig
  ): Promise<T> => {
    return this.doWebRequest(method, options)
  }

  doNativeRequest = async <T>(
    method: Method,
    options: AxiosConfig
  ): Promise<T> => {
    const { url, token, qs } = options
    const params = {
      api_key: this.apiKey,
      ...Object.entries(qs).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value?.toString() }),
        {}
      ),
    }

    const response = await CapacitorHttp.get({
      url: `${this.baseUrl}${this.version}/${url}`,
      headers: {
        authorization: `${token}`,
        'stream-auth-type': 'jwt',
      },
      params,
    } as HttpOptions)

    return response.data
  }

  doWebRequest = async <T>(
    method: Method,
    options: AxiosConfig
  ): Promise<T> => {
    this.send('request', method, options)

    try {
      const response = await this.request(
        this.enrichKwargs({ method, ...options })
      )
      return this.handleResponse(response)
    } catch (error) {
      const err = error as StreamApiError<T>
      if (err.response) return this.handleResponse(err.response)
      throw new SiteError(err.message)
    }
  }
}

/**
 * Create CustomStreamClient
 * @link https://getstream.io/activity-feeds/docs/node/feeds_getting_started/?language=js#setup
 * @method connect
 * @param  {string} apiKey    API key
 * @param  {string} [apiSecret] API secret (only use this on the server)
 * @param  {string} [appId]     Application identifier
 * @param {ClientOptions} [options] - additional options
 * @param {string} [options.location] - which data center to use
 * @param {boolean} [options.expireTokens=false] - whether to use a JWT timestamp field (i.e. iat)
 * @param {string} [options.version] - advanced usage, custom api version
 * @param {boolean} [options.keepAlive] - axios keepAlive, default to true
 * @param {number} [options.timeout] - axios timeout in Ms, default to 10s
 * @return {StreamClient}     StreamClient
 * @example <caption>Basic usage</caption>
 * stream.connect(apiKey, apiSecret);
 * @example <caption>or if you want to be able to subscribe and listen</caption>
 * stream.connect(apiKey, apiSecret, appId);
 * @example <caption>or on Heroku</caption>
 * stream.connect(streamURL);
 * @example <caption>where streamURL looks like</caption>
 * "https://thierry:pass@gestream.io/?app=1"
 */
export function connect<
  StreamFeedGenerics extends DefaultGenerics = DefaultGenerics
>(
  apiKey: string,
  apiSecret: string | null,
  appId?: string,
  options?: ClientOptions
) {
  if (typeof process !== 'undefined' && process.env?.STREAM_URL && !apiKey) {
    const parts =
      /https:\/\/(\w+):(\w+)@([\w-]*).*\?app_id=(\d+)/.exec(
        process.env.STREAM_URL
      ) || []
    apiKey = parts[1]
    apiSecret = parts[2]
    const location = parts[3]
    appId = parts[4]
    if (options === undefined) {
      options = {}
    }

    if (location !== 'getstream' && location !== 'stream-io-api') {
      options.location = location
    }
  }

  return new CustomStreamClient<StreamFeedGenerics>(
    apiKey,
    apiSecret,
    appId,
    options
  )
}
