import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { App } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'

import { Button } from '@/components/atoms/Button'
import { OnboardingSwiper } from '@/components/organisms/OnboardingSwiper'

import { useOnboarding } from '@/lib/hooks/useOnboarding'
import { Tracking, TrackingEvent } from '@/lib/tracking'

interface IOnboardingProps {
  finishOnboarding: () => void
}

export const Onboarding: FC<IOnboardingProps> = ({
  finishOnboarding,
}: IOnboardingProps) => {
  const { t } = useTranslation('common')
  const { data: onboardingData, isLoading, error } = useOnboarding()
  const [isActive, setIsActive] = useState<boolean>(true)
  const appStateChangeHandler = useRef<PluginListenerHandle | null>(null)

  useEffect(() => {
    appStateChangeHandler.current = App.addListener(
      'appStateChange',
      ({ isActive }) => setIsActive(isActive)
    )

    return () => {
      if (appStateChangeHandler) appStateChangeHandler.current.remove()
    }
  }, [])

  useEffect(() => {
    if (isActive && onboardingData?.id) {
      Tracking.triggerEvent(TrackingEvent.StartPageViewed, {
        onboardingId: onboardingData?.id,
      })
    }
  }, [isActive, onboardingData?.id])

  useEffect(() => {
    if (!isLoading && (error || !onboardingData?.items?.length)) {
      finishOnboarding()
    }
  }, [onboardingData?.items?.length, isLoading, error, finishOnboarding])

  return (
    <div className="relative w-full max-w-[40rem] mx-auto text-white">
      <OnboardingSwiper slidesData={onboardingData?.items} />
      <Button
        size="large"
        className="absolute z-10 bottom-16 left-[50%] translate-x-[-50%]"
        onClick={(event) => {
          event.stopPropagation()
          finishOnboarding()
        }}
      >
        <p className="px-4">
          {onboardingData?.cta?.title || t('onboarding.signUpLogin')}
        </p>
      </Button>
    </div>
  )
}
