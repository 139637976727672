import { CapacitorHttp, HttpOptions } from '@capacitor/core'

import { appConfig } from 'services/config'
import { getLanguage } from '@/utils/getLanguage'

export const getOnboarding = async () =>
  CapacitorHttp.get({
    url: `${appConfig.api}/onboardingV2`,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': getLanguage(), // 'vi' | 'vi-VN' (create a util mapper)
    },
  } as HttpOptions)
    .then((resp) => resp.data)
    .catch((error) => {
      console.error(error)
      return { id: '' }
    })
