import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera'
import { useQueryClient } from '@tanstack/react-query'

import { FileStorage } from '@/services/fileStorage'

async function uploadImage(image: Photo) {
  if (!image?.dataUrl) {
    return
  }

  const path = `avatar/avatar.${image.format}`
  const blob = await fetch(image.dataUrl).then((res) => res.blob())

  if (!path || !blob) {
    return
  }

  return FileStorage.put(path, blob)
}

export const useAvatarInput = () => {
  const { t } = useTranslation('common')
  const [cameraImage, setCameraImage] = useState<Photo>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [avatarError, setAvatarError] = useState<string>(null)
  const queryClient = useQueryClient()
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const takePicture = useCallback(async () => {
    try {
      setAvatarError(null)
      const image = await Camera.getPhoto({
        quality: 90,
        source: CameraSource.Photos,
        resultType: CameraResultType.DataUrl,
      })

      setUploading(true)
      setCameraImage(image)
      await uploadImage(image)

      queryClient.setQueryData(['cached_user_avatar'], image.dataUrl)

      const oldTimeout = queryClient.getQueryData<number>([
        'cached_avatar_timeout',
      ])
      clearTimeout(oldTimeout)

      const newTimeout = setTimeout(() => {
        queryClient.removeQueries({ queryKey: ['cached_user_avatar'] })
        queryClient.removeQueries({ queryKey: ['cached_avatar_timeout'] })
      }, 120000) // 2 minutes

      queryClient.setQueryData(['cached_avatar_timeout'], newTimeout)
    } catch (error) {
      console.log('Error while taking picture', error)
      setAvatarError(t('common.error.useJpgPngOnly'))

      if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current)
      errorTimeoutRef.current = setTimeout(() => setAvatarError(null), 5000)
    } finally {
      setUploading(false)
    }
  }, [queryClient, t])

  return {
    setCameraImage,
    takePicture,
    uploading,
    url: cameraImage?.dataUrl,
    avatarError,
    setAvatarError,
  }
}
