import { FC, useCallback, useContext, useEffect } from 'react'
import { FacebookLogin } from '@capacitor-community/facebook-login'

import { authSignOut, recordStopSession } from '@/services/awsAmplify'
import { removeWebPushNotificationsExternalUserId } from '@/services/webPushNotifications'
import { Context } from '@lib/store'
import { ActionType } from '@lib/store/reducer'
import { useConnectUser } from '@/lib/hooks/useChatClient'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { removeGetStreamUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'

export const RedirectToLogout: FC = () => {
  const navigate = useNavigate()
  const { dispatch } = useContext(Context)
  const { disconnectUser } = useConnectUser()
  const { data: userInfo } = useUserInfo()

  const signOut = useCallback(async () => {
    try {
      const signOutParams = {
        global: false,
      }
      await disconnectUser()
      await authSignOut(signOutParams).catch(() =>
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        authSignOut(signOutParams).catch(() => {})
      )
      queryClient.removeQueries()
      await FacebookLogin.getCurrentAccessToken()
        .then(() => FacebookLogin.logout())
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})

      removeWebPushNotificationsExternalUserId(userInfo?.id)

      dispatch({
        type: ActionType.SIGN_IN_REDIRECT,
        payload: undefined,
      })
      removeGetStreamUserInfo()
      recordStopSession()
    } catch (e) {
      console.log(e)
    }
  }, [disconnectUser, dispatch, userInfo?.id])

  useEffect(() => {
    signOut().then(() => {
      navigate(Routes.AUTH_SIGN_UP)
    })
  }, [navigate, signOut])

  return null
}
