import { FC } from 'react'

import { InstagramIcon } from '@/components/atoms/assets/InstagramIcon'
import { TiktokIcon } from '@/components/atoms/assets/TiktokIcon'
import { TwitterIcon } from '@/components/atoms/assets/TwitterIcon'
import { Socials, SocialsType } from '@/components/pages/Socials/enums'
import { useSocials } from '@/components/pages/Socials/hooks/useSocials'

import { IUserInfoItem } from '@/services/socials'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { openExternalLink } from '@/utils/openExternalLink'

import { QueryKeys } from '@/enums'

interface ISocialsListProps {
  isAuthUser: boolean
  userId: UUID
}

const INSTAGRAM_ORIGIN = 'https://www.instagram.com/'
const TWITTER_ORIGIN = 'https://www.x.com/'
const TIKTOK_ORIGIN = 'https://www.tiktok.com/@'

export const SocialsList: FC<ISocialsListProps> = ({ isAuthUser, userId }) => {
  const navigate = useNavigate()

  const { data: authUser } = useUserInfo()
  const { socialsList, isSocialsListLoading, socialsListError } = useSocials({
    userId,
  })

  if (isSocialsListLoading || socialsListError) return

  const RenderItem = ({
    action,
    key,
    isActive,
  }: {
    action: () => void
    key?: string
    isActive?: boolean
  }) => {
    return (
      <li className="flex items-center" key={key}>
        <div
          onClick={action}
          className="ml-1 pr-1 xs:pr-2 sm:pr-3 cursor-pointer"
        >
          {key === Socials.Instagram && (
            <InstagramIcon
              className="sm:!w-7 sm:!h-7 mr-0.5"
              isActive={isActive}
            />
          )}
          {key === Socials.Twitter && (
            <TwitterIcon
              className="!w-5 !h-5 sm:!w-6 sm:!h-6"
              isActive={isActive}
            />
          )}
          {key === Socials.Tiktok && (
            <TiktokIcon className="sm:!w-7 sm:!h-7" isActive={isActive} />
          )}
        </div>
      </li>
    )
  }

  if (isAuthUser) {
    const authSocialsList = [
      {
        provider: Socials.Instagram,
        fallbackRoute: Routes.SOCIALS_CONNECT_INSTAGRAM,
        origin: INSTAGRAM_ORIGIN,
      },
      {
        provider: Socials.Twitter,
        fallbackRoute: Routes.SOCIALS_CONNECT_TWITTER,
        origin: TWITTER_ORIGIN,
      },
      {
        provider: Socials.Tiktok,
        fallbackRoute: Routes.SOCIALS_CONNECT_TIKTOK,
        origin: TIKTOK_ORIGIN,
      },
    ]

    const getSocialData = (provider: Socials) => {
      return socialsList?.items?.filter((item) => {
        return item.provider === provider
      })?.[0]
    }

    return (
      <ul className="flex">
        {authSocialsList.map((item) => {
          const socialData = getSocialData(item.provider)

          return RenderItem({
            action: () => {
              if (socialData) {
                openExternalLink({
                  url: `${item.origin}${socialData?.providerUserName}`,
                })
              } else {
                navigate(item.fallbackRoute)
                queryClient.setQueryData(
                  [QueryKeys.SocialBackUrl],
                  `/@${authUser?.username}`
                )
              }
            },
            key: item.provider,
            isActive: !!socialData,
          })
        })}
      </ul>
    )
  }

  const getOrigin = (provider: SocialsType) => {
    switch (provider) {
      case Socials.Instagram:
        return INSTAGRAM_ORIGIN
      case Socials.Twitter:
        return TWITTER_ORIGIN
      case Socials.Tiktok:
        return TIKTOK_ORIGIN
    }
  }

  return (
    <ul className="flex">
      {socialsList?.items?.map((item: IUserInfoItem) =>
        RenderItem({
          action: () => {
            window.location.assign(
              `${getOrigin(item.provider)}${item.providerUserName}`
            )
          },
          key: item.provider,
          isActive: true,
        })
      )}
    </ul>
  )
}
