import {
  CSSProperties,
  FC,
  memo,
  MutableRefObject,
  SyntheticEvent,
} from 'react'
import {
  Components,
  ItemContent,
  ScrollSeekConfiguration,
  StateSnapshot,
  Virtuoso,
  VirtuosoHandle,
} from 'react-virtuoso'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useQueryClient,
} from '@tanstack/react-query'

import { useNetwork } from '@/lib/hooks/useNetwork'
import { useLocation } from '@/lib/routing'

import { QueryKeys } from '@/enums'

interface IVirtualListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  className: string
  endReached: (
    options?: FetchNextPageOptions | number
  ) =>
    | Promise<InfiniteQueryObserverResult<unknown, unknown>>
    | ReturnType<typeof setInterval>
    | void
  components?: Components
  itemContent: ItemContent<string, number>
  virtualKey?: string
  overscan?: number | { main: number; reverse: number }
  increaseViewportBy?: number | { top: number; bottom: number }
  virtualListRef?: MutableRefObject<VirtuosoHandle>
  scrollSeekConfiguration?: ScrollSeekConfiguration | false
  atTopStateChange?: (atTop: boolean) => void
  onScroll?: (scrollTop: SyntheticEvent) => void
  style?: CSSProperties
}

const VirtualListComponent: FC<IVirtualListProps> = ({
  data,
  className,
  endReached,
  components,
  itemContent,
  virtualKey,
  overscan = 0,
  increaseViewportBy = 0,
  virtualListRef = null,
  scrollSeekConfiguration,
  atTopStateChange,
  onScroll,
  style,
}) => {
  const queryClient = useQueryClient()
  const { isOnline } = useNetwork()
  const { pathname } = useLocation()

  const scrollRestorationForm = queryClient.getQueryData<{
    ranges: []
    scrollTop: number
  }>([QueryKeys.ScrollRestorationForm, pathname])

  const { ranges = [], scrollTop = 0 } = scrollRestorationForm || {}

  return (
    <>
      <Virtuoso
        style={style}
        key={virtualKey}
        atTopStateChange={atTopStateChange}
        data={data}
        className={className}
        endReached={isOnline ? endReached : undefined}
        onScroll={(e) => {
          virtualListRef?.current?.getState((snapshot: StateSnapshot) => {
            snapshot &&
              queryClient.setQueryData(
                [QueryKeys.ScrollRestorationForm, pathname],
                snapshot
              )
          })
          onScroll?.(e)
        }}
        overscan={overscan}
        increaseViewportBy={increaseViewportBy}
        components={components}
        itemContent={itemContent}
        ref={virtualListRef}
        restoreStateFrom={{
          ranges,
          scrollTop,
        }}
        scrollSeekConfiguration={scrollSeekConfiguration}
      />
    </>
  )
}

export const VirtualList = memo(VirtualListComponent)
