import { Browser } from '@capacitor/browser'

export const openExternalLink = async ({
  url,
  windowName,
  toolbarColor,
  presentationStyle = 'popover',
  width,
  height,
}: {
  url: string
  windowName?: string
  toolbarColor?: string
  presentationStyle?: 'fullscreen' | 'popover'
  width?: number
  height?: number
}) => {
  try {
    await Browser.open({
      url,
      windowName,
      toolbarColor,
      width,
      height,
      presentationStyle,
    })
  } catch (e) {
    handleOpenError(e)
  }
}

export const handleOpenError = (error: Error): void => {
  console.error(`Browser open external link is failed: ${error}`)
}
