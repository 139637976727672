import { FacebookLogin } from '@capacitor-community/facebook-login'

import { appConfig } from './config'
export const connectSsoIntegration = async () => {
  if (appConfig.ssoVisible) {
    await FacebookLogin.initialize({
      appId: appConfig.facebookAppId,
      autoLogAppEvents: true,
      version: 'v16.0',
      xfbml: false,
    })
  }
}
