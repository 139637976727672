import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons, IonIcon, IonTitle } from '@ionic/react'

import { BackButton } from '@/components/atoms/BackButton'
import { Currency } from '@/components/atoms/Currency'
import { Title } from '@/components/atoms/Title'
import { Entry } from '@/components/templates/entry'

import { appConfig } from '@/services/config'
import { useTokenDetails } from '@/lib/hooks/useTokenDetails'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { useWallet } from '@/lib/hooks/wallet'

export const PriceBooster: FC = () => {
  const { t } = useTranslation()
  const { data: authUser } = useUserInfo()
  const { data: tokenDetails } = useTokenDetails(authUser?.creatorTokenId)
  const { wallet } = useWallet({ userInfo: authUser })

  const { creatorToken, currency } = appConfig

  const header = (
    <>
      <IonButtons slot="start">
        <BackButton className="text-white" />
      </IonButtons>
      <IonTitle className="!px-0">
        <Title level={3}>
          {t('common.head.title.priceBooster', { creatorToken })}
        </Title>
      </IonTitle>
      <IonButtons slot="end">
        <IonIcon slot="icon-only" />
      </IonButtons>
    </>
  )

  const bgStyles = {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  } as React.CSSProperties

  return (
    <Entry
      headTitle={t('common.head.title.priceBooster', { creatorToken })}
      header={header}
      className="h-full"
      isPaddingEnabled={false}
    >
      <div
        className={`px-8 pt-8 bg-[url('/trading_app/background.png')] md:bg-[url('/trading_app/desktop-background.png')]`}
        style={{
          ...bgStyles,
        }}
      />
      <div className="flex flex-col px-4 py-6 sm:py-8 items-center">
        <div className="w-full sm:w-auto sm:min-w-[20rem] h-full self-start bg-[rgba(159,61,255,0.20)] rounded-[1.25rem] border border-[rgba(255,255,255,0.20)] p-6 mb-10 sm:mx-4">
          <div className="opacity-70 text-white text-[0.875rem] font-bold uppercase mb-2">
            {t('priceBooster.priceBoosterUpToDate', { creatorToken })}
          </div>
          <div className="text-white text-[2rem] font-bold">
            <Currency
              value={
                tokenDetails?.totalBoost ||
                wallet?.accumulativeRevenueShareBalance ||
                0
              }
            />
          </div>
        </div>
        <div className="px-4 mb-8">
          <span className="flex flex-col text-white text-[1.125rem] font-bold mb-2">
            {t('priceBooster.whatIsPriceBoosterTitle', { creatorToken })}
          </span>
          <span className="text-sm text-cool-gray-200">
            {t('priceBooster.whatIsPriceBoosterDescription', {
              creatorToken,
              currency,
            })}
          </span>
        </div>
        <img
          className="w-[80%] mb-7"
          src="/trading_app/price-booster-graph.svg"
          alt="price-booster-graph"
        />
        <div className="w-full flex flex-col px-4">
          <span className="text-[1.25rem] font-bold mb-2">
            {t('priceBooster.learnMoreTitle')}
          </span>
          <span className="text-sm text-cool-gray-200 mb-2">
            {t('priceBooster.learnMoreDescription', { creatorToken })}
          </span>
        </div>
      </div>
    </Entry>
  )
}
