import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Dialog } from '@capacitor/dialog'
import { useMutation } from '@tanstack/react-query'

import { Footer } from '@components/organisms/Footer'
import { Login } from '@components/templates/login'
import ConsentForm from '@/components/pages/Auth/Consent/ConsentForm'

import { verifyAge } from '@services/auth'
import { errorBoundary } from '@/services/errorBoundary'
import { FacebookAuthError } from '@/services/sso-signin'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate, useLocation, useNavigate } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { Routes } from '@/router/routes'
import { formatISOString } from 'utils/date'

export const Consent: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data: authUser } = useUserInfo()

  const { mutate, isPending } = useMutation({
    mutationKey: ['verifyAge'],
    mutationFn: verifyAge,
    onSuccess: (data) => {
      Tracking.triggerEvent(TrackingEvent.ConsentConfirmed, {
        timestamp: formatISOString(data.createdAt),
      })
      navigate(Routes.AUTH_CREATE_PROFILE, {
        replace: true,
      })
    },
    onError: (e) => {
      if (e instanceof Error && FacebookAuthError[e?.message]) {
        Dialog.alert({
          message: t(`registration.sso.error.${FacebookAuthError[e?.message]}`),
        })
      }
      errorBoundary(e)
    },
  })

  const { t } = useTranslation('common')

  const onSubmit = async (fieldValues: {
    consent: boolean
    terms: boolean
  }) => {
    if (!Object.values(fieldValues).includes(false)) {
      mutate()
    }
  }

  const title = (
    <Trans i18nKey="registration.consent.title">
      {t('registration.consent.title')}
    </Trans>
  )

  if (
    authUser?.ageConsent &&
    location.pathname !== Routes.AUTH_CREATE_PROFILE
  ) {
    return <Navigate to={Routes.AUTH_CREATE_PROFILE} />
  }

  return (
    <Login title={title}>
      <ConsentForm onChange={onSubmit} isLoading={isPending} />
      <Footer className="pt-4 pb-4" />
    </Login>
  )
}
