import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Currency } from '@/components/atoms/Currency'
import { WalletHero } from '@/components/atoms/WalletHero'
import { StakeButton } from '@/components/molecules/StakeButton'

import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { toTokenCentsString } from '@/utils/token'

import { BuyButton } from '../BuyButton'
import { DepositButton } from '../DepositButton'
import { PriceBoosterButton } from '../PriceBoosterButton'
import { StakingVaultButton } from '../StakingVaultButton'
import { WithdrawButton } from '../WithdrawButton'

interface TransactionHistoryHeaderProps {
  balance?: string
  stakedBalance?: string
  totalBoost?: number
  accumulativeRevenueShareBalance?: string
}

export const TransactionHistoryHeader: FC<TransactionHistoryHeaderProps> = ({
  balance = '0',
  stakedBalance = '0',
  totalBoost,
  accumulativeRevenueShareBalance,
}: TransactionHistoryHeaderProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const priceBooster = totalBoost || accumulativeRevenueShareBalance

  const handleBannerClick = useCallback(
    () => navigate(Routes.PRICE_BOOSTER),
    [navigate]
  )

  return (
    <div className="p-4">
      <WalletHero
        handleOnClick={handleBannerClick}
        title={t('wallet.yourBalance')}
        footer={
          !!Number(priceBooster) && <PriceBoosterButton value={priceBooster} />
        }
      >
        <div className="flex flex-col pb-2">
          <span
            className="text-[8vw] sm:text-[3rem] font-bold"
            title={toTokenCentsString(balance)}
          >
            <Currency value={balance} isHighlighted />
          </span>
        </div>
        <div className="flex flex-start flex-wrap gap-x-1 xs:gap-x-2 gap-y-2 mb-6">
          <BuyButton />
          <DepositButton />
          <StakeButton
            hasIcon={false}
            fill="outline"
            text={t('wallet.stake.stake')}
          />
          <WithdrawButton />
        </div>
        <StakingVaultButton stakedAmount={stakedBalance} />
      </WalletHero>
    </div>
  )
}
