import { getCurrentUser } from 'aws-amplify/auth'

import { get, put } from '@/services/awsAmplify'
import { UserInfo } from '@/services/user'

export const checkUsernameUniqueness = async (username: string) => {
  const data = await get({
    path: `/profile/username?q=${username}`,
  })
  return data as { usernames: string[] }
}

export const updateProfile = async ({ userData }) => {
  const { username } = await getCurrentUser()
  const data = await put({
    path: `/profile/${username}`,
    params: {
      ...userData,
    },
  })
  return data as unknown as UserInfo
}
