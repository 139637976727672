import { Capacitor } from '@capacitor/core'

import { UgcComposer } from '.'
import { IUgcComposerProps } from './definitions'

export const ugcComposer = async (options: IUgcComposerProps = {}) => {
  const result = await UgcComposer.openWizard(options)

  if ('path' in result.media) {
    return {
      ...result,
      media: {
        ...result.media,
        url: Capacitor.convertFileSrc(result.media.path),
      },
    }
  }

  if ('url' in result.media) {
    return result
  }

  throw new Error('video editor error')
}
