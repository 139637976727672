import { FC, useCallback, useEffect, useState } from 'react'
import { IonAvatar } from '@ionic/react'

import { useCachedAvatar } from '@/lib/hooks/useCachedAvatar'

import { Badge } from '@/enums'

import { ShieldIcon } from '../assets/ShieldIcon'
import { EmptyAvatar } from './EmptyAvatar'

interface IAvatarInputProps {
  url?: string
  uploading?: boolean
  onClick?: () => void
  className?: string
  hasIcon?: boolean
  hasText?: boolean
  badge?: Badge
  isModerator?: boolean
  isAmbassador?: boolean
  isValid?: boolean
  hasBorder?: boolean
}

export const AvatarInput: FC<IAvatarInputProps> = ({
  url,
  uploading = false,
  onClick,
  className = '',
  hasIcon = false,
  hasText,
  badge,
  isModerator = false,
  isAmbassador = false,
  isValid = true,
  hasBorder = false,
}) => {
  const [imageSrc, setImageSrc] = useState(url)
  const [avatarStyle, setAvatarStyle] = useState(null)
  const cachedAvatar = useCachedAvatar()

  const getAvatarStyle = useCallback(() => {
    if (isModerator) {
      return 'border-[4px] border-white'
    }

    if (isAmbassador) {
      return 'border-[4px] border-primary'
    }

    switch (badge) {
      case Badge.BronzeXPMilestoneBadge:
        return 'border-[4px] border-bronze'
      case Badge.SilverXPMilestoneBadge:
        return 'border-[4px] border-silver'
      case Badge.GoldXPMilestoneBadge:
        return 'border-[4px] border-gold'
      case Badge.DiamondXPMilestoneBadge:
        return 'border-[4px] border-diamond'
      default:
        return 'border-[1px] border-cool-gray-400'
    }
  }, [badge, isModerator, isAmbassador])

  useEffect(() => {
    cachedAvatar && setImageSrc(cachedAvatar)
  }, [cachedAvatar])

  useEffect(() => {
    setAvatarStyle(getAvatarStyle())
  }, [badge, isAmbassador, getAvatarStyle])

  return (
    <div
      className={`w-[8rem] h-[8rem] relative mx-auto rounded-full ${className} ${
        onClick ? 'cursor-pointer' : ''
      } justify-center items-center`}
      onClick={onClick}
    >
      {!uploading && imageSrc && !imageSrc.includes('undefined') ? (
        <IonAvatar
          className={`w-full h-full flex justify-center items-center ${avatarStyle}`}
        >
          <img src={imageSrc} className="w-full h-full" alt="Profile Avatar" />
        </IonAvatar>
      ) : (
        <EmptyAvatar
          hasIcon={hasIcon}
          uploading={uploading}
          hasText={hasText}
          isValid={isValid}
          hasBorder={hasBorder}
        />
      )}
      {(isModerator || isAmbassador) && (
        <div className="absolute bottom-[0.5rem] right-[0.125rem]">
          <ShieldIcon {...(isModerator ? { color: '#FFFFFF' } : {})} />
        </div>
      )}
    </div>
  )
}
