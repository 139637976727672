import { FC, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner } from '@ionic/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import debounce from 'lodash/debounce'

import { AvatarInput, useAvatarInput } from '@components/atoms/Avatar'
import { Button } from '@components/atoms/Button'
import { Title } from '@components/atoms/Title'
import { Login } from '@components/templates/login'

import { updateProfile } from '@services/updateProfile'
import { ApiError } from '@/services/awsAmplify/api'
import { errorBoundary } from '@/services/errorBoundary'
import { UserInfo } from '@/services/user'
import { queryClient } from '@lib/queryClient'
import { useCachedAvatar } from '@/lib/hooks/useCachedAvatar'
import { useGtm } from '@/lib/hooks/useGtm'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Navigate, useLocation } from '@/lib/routing'
import {
  amplitudeIdentifyEvent,
  amplitudeSetUserId,
  IIdentifyEvent,
  newrelicNoticeError,
  Tracking,
  TrackingEvent,
} from '@/lib/tracking'
import { getStreamSetUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'
import setFormErrorMessages from '@utils/setFormErrorMessages'
import { authType } from '@/utils/utils'
import { formatISOString } from 'utils/date'

import { Platform } from '@/enums'

interface userInfo {
  username: string
  displayName: string
}

export const CreateProfileAvatar: FC = () => {
  const { trackEvent } = useGtm()
  const { data: userInfo } = useUserInfo()
  const { t } = useTranslation('common')
  const location = useLocation()
  const state = location?.state as userInfo
  const cachedAvatar = useCachedAvatar()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      avatar: cachedAvatar,
    },
    mode: 'onSubmit',
  })

  const { url, takePicture, uploading, avatarError } = useAvatarInput()

  useEffect(() => {
    if (!url) return

    setValue('avatar', url)
    trigger('avatar')
  }, [url, setValue, trigger])

  const tracking = useCallback(
    (data: UserInfo) => () => {
      trackEvent({
        event: TrackingEvent.SignUp,
        value: {
          authType: authType(Boolean(data.email)),
          userId: data.id,
        },
      })
      Tracking.triggerEvent(TrackingEvent.ProfileCreated, {
        timestamp: formatISOString(data.createdAt),
      })
      amplitudeSetUserId(data.id)
      amplitudeIdentifyEvent<IIdentifyEvent>({
        userId: data.id,
        followerCount: data.followerCount,
        followingCount: data.followingCount,
        userName: data.username,
        refUserName: data?.refUsername || '-',
        isAmbassador: userInfo?.isAmbassador,
        badge: userInfo?.badge || '-',
        appPlatform: Platform.Web,
      })
    },
    [trackEvent, userInfo?.badge, userInfo?.isAmbassador]
  )

  const { mutate, isPending, error } = useMutation({
    mutationKey: ['updateProfile'],
    mutationFn: updateProfile,
    onSuccess: async (data: UserInfo) => {
      tracking(data)
      getStreamSetUserInfo(data.id, data.username)
      queryClient.setQueryData(['user'], data)
    },
    onError: (error: AxiosError) => {
      console.error('Error while updating profile', error)
      newrelicNoticeError({
        error,
        customAttributes: {
          location: 'CreateProfileAvatar',
          username: state?.username,
          displayName: state?.displayName,
        },
      })
      errorBoundary(error)
      setFormErrorMessages({ error, setError, t })
    },
  })

  const onSubmit = debounce(
    useCallback(
      async (_, event) => {
        event.stopPropagation()

        if (url) {
          mutate({
            userData: state,
          })
        }
      },
      [mutate, state, url]
    ),
    100
  )

  if (!state?.username) {
    return <Navigate to={Routes.AUTH_CREATE_PROFILE} />
  }

  return (
    <Login toolbarClassName="h-0">
      <Title className="mb-36" level={1}>
        <Trans i18nKey="registration.profile.uploadAvatar">
          {t('registration.profile.uploadAvatar')}
        </Trans>
      </Title>
      <form className="px-14 py-14 relative" onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto absolute w-[8rem] h-[8rem] top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer">
          <Controller
            control={control}
            name="avatar"
            rules={{
              required: true,
            }}
            render={() => (
              <AvatarInput
                url={url}
                onClick={takePicture}
                uploading={uploading}
                hasIcon
                hasText
                isValid={!errors?.avatar}
                hasBorder
              />
            )}
          />
        </div>
        {(avatarError || error?.response) && (
          <IonLabel color="danger" className="text-center block mt-8">
            {avatarError || (error as ApiError)?.response?.metadata?.error}
          </IonLabel>
        )}
        {errors?.avatar && (
          <IonLabel color="danger" className="text-center block mt-8">
            {t('registration.profile.avatarIsRequired')}
          </IonLabel>
        )}
        <Button
          size="large"
          isDisabled={uploading}
          className={`${
            avatarError || error?.response || errors?.avatar ? 'mt-6' : 'mt-20'
          } flex mx-auto w-lg`}
          onClick={handleSubmit(onSubmit)}
        >
          {isPending ? (
            <IonSpinner color="light" />
          ) : (
            t('registration.profile.create')
          )}
        </Button>
      </form>
    </Login>
  )
}
